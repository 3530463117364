import React from 'react';
import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Home from "../views/home/Home.js"
import Contact from "../views/contact/Contact"
import Resume from "../views/resume/Resume.js"
import Projects from "../views/projects/Projects.js"
// import ProjectDetails from "../views/projectDetails/ProjectDetails.js"

import Header from "../components/header/Header.js"

function App() {
  return (
    <div className="App">
    <Router>
    <Header />
      <Routes>
        <Route path='/'         element={<Home />}    />
        <Route path='/contact'  element={<Contact />} />
        <Route path='/resume'   element={<Resume />}  />
        <Route path='/projects' element={<Projects />}/>
        {/* <Route path='/projects/:projectId' element={<ProjectDetails />} /> */}
      </Routes>
    </Router>
    </div>
  );
}

export default App;
