// eslint-disable-next-line
import React, {useState, useEffect} from 'react'
import './Home.css'
import profile_picture from '../../assets/images/profile_pic.jpeg'

function Home(props) {
    return (
        <div className='Home' >
            <img className='ProfilePicture' src={profile_picture} alt='profile'/>
            <div className='HomeText'>
                <h1>Hi, I'm <span className='Name'>Gavin</span></h1>
                <h2>I'm a <span className='Job'>Computer Engineer</span></h2>
            </div>
        </div>
    );
}
export default Home;