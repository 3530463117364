// eslint-disable-next-line
import React, {useState, useEffect} from 'react'
import './Contact.css'

function Contact(props) {
    return (
        <div className='Contact' >
        </div>
    );
}

export default Contact;