// eslint-disable-next-line
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import './Projects.css'
import Project from '../../components/project/Project'
import projects from '../../data/projects.json'

function generateProjectList(proj_json) {
    return Object.keys(proj_json).map(key => {
        return (
            <Link key={key} to={`/projects/${key}`} className='ProjectLink'>
                <Project 
                    key={projects[key].uid} 
                    uid={projects[key].uid}
                    image={projects[key].imagePath}
                    title={projects[key].title}
                    startDate={projects[key].startDate}
                    endDate={projects[key].endDate}
                />
            </Link>
        )
    }
)}

const projectList = insertBreaks(generateProjectList(projects), 4)
console.log(projectList)

function insertBreaks(components, n) {
    // Insert a break after every nth component
    for (var i = n; i < components.length; i += (n+1)) {
        components.splice(i, 0, <div className='break' key={i}/>)
    }
    return components
}


function Projects(props) {
    return (
        <div className='Projects' >
            <h1>Projects</h1>
            <div className='ProjectList'>
            {projectList}
            </div>
        </div>
    );
}
export default Projects;