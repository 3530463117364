import React from 'react';
import './Header.css'
import {Link} from 'react-router-dom';


/**
 * 
 * @param {String} props.title - The title of the page
 * @returns 
 */
const Header = (props) => {
    return (
        <div className='Header'>
            <Link className='header-item' to="/">Home</Link>
            <Link className='header-item' to="/projects">Projects</Link>
            <Link className='header-item' to="/resume">Resume</Link>
            <Link className='header-item' to="/contact">Contact</Link>
        </div>
    );
}

export default Header;