// eslint-disable-next-line
import React, {useState, useEffect} from 'react'
import './Resume.css'
import ResumePDF from '../../assets/documents/resume.pdf'

function Resume(props) {
    return (
        <div className='Resume' >
            <embed src={ResumePDF} type="application/pdf" width="100%" height="600px" />
        </div>
    );
}

export default Resume;